jQuery(document).ready(function($){
	$.getScript( "//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js", function(data, textStatus, jqxhr) {
		if( textStatus=='success' ){
			var p;
			cookieconsent.initialise({
				'revokable': true,
				'palette': {
					'popup': {
						'background': '#000000',
						'text': '#ffffff'
					},
					'button': {
						'background': '#8effc8',
						'text': '#000000'
					}
				},
				'position': 'bottom-right',
				'type': 'opt-out',
				'theme': 'edgeless',
				'law': {
					'regionalLaw': false,
				},
				'location': false,
				onInitialise: function (status) {
					var didConsent = this.hasConsented();
					if( !didConsent ){
						jQuery(document).trigger('cookies_disabled');
					}
				},
				onStatusChange: function(status) {
					if( this.hasConsented() ){
						// enable cookies
					}
					else{
						// disable cookies
						jQuery(document).trigger('cookies_disabled');

						setTimeout(function(){
							var cookies = document.cookie.split(";");

							for (var i = 0; i < cookies.length; i++) {
								var cookie = cookies[i];
								var eqPos = cookie.indexOf("=");
								var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
								document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=" + document.domain + ";path=/";
								console.log(cookies[i]);
							}
						}, 100);
					}
				}
			},
			function (popup) {
				p = popup;
			});

			document.getElementById('cookiebar-open').onclick = function (e) {
				p.open();
			};
		}
	});
	$('#cookiebar-open').click(function(e){
		e.preventDefault();
		$(this).addClass('hidden');
	});

	$(window).on('cookies_disabled', function(){
		$('#cookiebar-open').removeClass('hidden');
	});
});